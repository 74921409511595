.purpose-main {
  min-height: 100vh;
  background: #c6ced5;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.purpose-content {
  z-index: 1;
  text-align: center;
  color: #0f3a57;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.purpose-img img {
  width: 100vw;
}

@media only screen and (max-width: 480px) {
  .purpose-icon {
    width: 100px;
    margin-bottom: 8px;
  }

  .purpose-content p {
    max-width: 340px;
  }

  .purpose-content h1 {
    max-width: 352px;
  }

  .cloud1 {
    width: 340px;
  }

  .cloud2 {
    width: 200px;
  }

  .cloud3 {
    width: 550px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .purpose-content p {
    width: 400px;
  }

  .purpose-content h1 {
    width: 425px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .purpose-content p {
    width: 400px;
  }

  .purpose-content h1 {
    width: 590px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .purpose-content p {
    width: 490px;
  }

  .purpose-content h1 {
    width: 675px;
  }
}

@media only screen and (min-width: 1201px) {
  .purpose-content p {
    width: 640px;
  }

  .purpose-content h1 {
    width: 755px;
  }
}

/*# sourceMappingURL=index.4d5ab2df.css.map */
